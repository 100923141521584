import { type FC, type ReactNode, useEffect, useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useLocation, useNavigate } from 'react-router-dom';
interface MultiTabProps {
  tabs: {
    title: string;
    component?: ReactNode;
  }[];
  initialSelectedIndex?: number;
}

const MultiTab: FC<MultiTabProps> = ({ tabs, initialSelectedIndex = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(initialSelectedIndex);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentTabIndex = tabs.findIndex(
      (tab) =>
        `#${tab.title.toLowerCase()}` ===
        decodeURIComponent(location.hash.toLowerCase())
    );

    if (currentTabIndex !== -1 && activeIndex !== currentTabIndex) {
      setTabIndex(currentTabIndex);
    }
  }, [location.hash, tabs]);

  const setTabIndex = (index: number): void => {
    setActiveIndex(index);
    const encodedTitle = tabs[index].title.toLowerCase();

    navigate(`${location.pathname}#${encodeURIComponent(encodedTitle)}`);
  };

  return (
    <div className="w-full relative sm:px-0">
      <TabGroup selectedIndex={activeIndex}>
        <TabList className="flex">
          {tabs.map((tab, index) => (
            <Tab
              key={tab.title}
              className={({ selected }) =>
                `border-b-[3px] py-3 px-6 text-base font-medium rounded-t-md ${
                  selected
                    ? 'border-tertiary text-gray-60 bg-tab'
                    : 'border-step text-gray-50'
                }`
              }
              onClick={() => {
                setTabIndex(index);
              }}
            >
              {tab.title}
            </Tab>
          ))}
        </TabList>

        <TabPanels className="mt-6">
          {tabs.map((tab) => (
            <TabPanel key={tab.title} className={'rounded-xl'}>
              {tab.component}
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </div>
  );
};
export default MultiTab;
