import { Modal } from '@components/modal';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ManageGroupForm } from '../../form/ManageGroupForm';
import { groupSchema } from '../../form/ManageGroupForm/schema';
import { useCreateGroup } from 'api/assets/assetsServices';
import queryClient from 'lib/queryClient';

interface CreateGroupModalProps extends Disclosure {}

const CreateGroupModal: FC<CreateGroupModalProps> = ({
  isOpen,
  handleClose,
}) => {
  const { methods } = useHRForm({ schema: groupSchema });
  const { mutate, isSuccess, isPending } = useCreateGroup();

  const { t } = useTranslation();

  const handleCreateGroup = async (): Promise<void> => {
    const body = methods.getValues();
    const parsedMetersId = body.meters
      ?.filter((meter) => meter.value !== 'selectAll')
      .map((meter) => ({ id: meter.value }));

    mutate({ ...body, meters: parsedMetersId });
  };

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    const refetch = async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: ['listGroups'] });
    };
    handleClose();
    void refetch();
  }, [isSuccess]);

  useEffect(() => {
    if (isOpen) {
      methods.reset({});
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t('assetsPage.group.createAGroup')}
      useSkeleton={true}
      maxWidth={550}
      actionBtns={{
        primary: {
          label: t('assetsPage.group.createGroup'),
          type: 'submit',
          isLoading: isPending,
          onClick: methods.handleSubmit(handleCreateGroup),
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
    >
      <div className="phone:min-h-0 tablet:min-h-[500px]">
        <FormProvider {...methods}>
          <ManageGroupForm />
        </FormProvider>
      </div>
    </Modal>
  );
};
export default CreateGroupModal;
