import { SwitchButton } from '@components/switchButton';
import { CardContainer } from 'layout/Card';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DashboardMap } from './components/DashboardMap';
import { DashboardTable } from './table';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { ProjectsPerformanceTables } from './components/ProjectsPerformanceTables';
import { PermissionWrapper } from '@components/PermissionWrapper';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';

const DashboardPage: FC = () => {
  const [impactPerDayToggle, setImpactPerDayToggle] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({
    impact_unit: false,
    impact_euro: true,
  });
  const [hoveredMeterId, setHoveredMeterId] = useState<number | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    if (impactPerDayToggle) {
      setColumnVisibility({ impact_unit: true, impact_euro: false });
    } else {
      setColumnVisibility({ impact_unit: false, impact_euro: true });
    }
  }, [impactPerDayToggle]);

  return (
    <div>
      <div className="mb-8">
        <h1 className="mb-2">{t('dashboardPage.title')}</h1>
        <h3 className="text-base">{t('dashboardPage.subTitle')}</h3>
      </div>
      <div className="flex flex-col gap-8">
        <div
          className="uppercase flex justify-between items-center"
          data-testid="top-five-changes-header"
        >
          <div className="flex items-center gap-2">
            <h2 className="text-base">
              {t('dashboardPage.topFiveDetections')}
            </h2>
            <TooltipIconComponent content={t('dashboardPage.topFiveTooltip')} />
          </div>
          <Link
            to={'/app/operations/anomalies'}
            className="text-tertiary-dark font-bold"
          >
            {t('common.buttons.seeAll')}
          </Link>
        </div>
        <CardContainer data-testid="changes-container">
          <div className="flex justify-between items-center mb-6">
            <h4 className="text-base font-bold">
              {t('dashboardPage.mostImportant')}
            </h4>
            <SwitchButton
              label={t('dashboardPage.showImpactPerDay')}
              checked={impactPerDayToggle}
              onChange={() => {
                setImpactPerDayToggle(!impactPerDayToggle);
              }}
            />
          </div>
          <DashboardTable
            columnVisibility={columnVisibility}
            onRowHover={setHoveredMeterId}
            hoveredId={hoveredMeterId}
          />
        </CardContainer>
        <PermissionWrapper validate={FEATURE_FLAGS_LIST.project.listDashboard}>
          <ProjectsPerformanceTables
            hoveredId={hoveredMeterId}
            onRowHover={setHoveredMeterId}
          />
        </PermissionWrapper>

        <div className="flex flex-col gap-6">
          <h5>{t('dashboardPage.mapTitle')}</h5>
          <DashboardMap
            hoveredId={hoveredMeterId}
            setHoveredId={setHoveredMeterId}
          />
        </div>
      </div>
    </div>
  );
};
export default DashboardPage;
