import axiosInstance, { type CustomAxiosRequestConfig } from 'api/axios';
import { type LoginSchemaType } from 'pages/Authentication/LoginPage/forms/LoginForm/schema';
import { handleError } from 'utils/handleError/handleError';
import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { type NavigateFunction } from 'react-router-dom';
import { type ResetPasswordSchemaType } from 'pages/Authentication/ResetPassword/ResetPasswordPage/forms/schema';
import { clearStorage, navigateToLoginPage } from 'utils/auth/authUtils';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';

export const login = async ({
  email,
  password,
}: LoginSchemaType): Promise<AxiosResponse | undefined> => {
  try {
    const response = await axiosInstance.post(`auth/login`, {
      email,
      password,
    });
    const { data, status } = response;
    if (status === 200 && data?.token) {
      localStorage.setItem('jwtToken', data.token);
      localStorage.setItem('refreshToken', data.refresh_token);
    }
    return response;
  } catch (error: any) {
    handleError(error, 'Incorrect username or password');
  }
};

export const refreshToken = async (): Promise<any> => {
  try {
    const localRefreshToken = localStorage.getItem('refreshToken');
    if (!localRefreshToken) return;

    const config: CustomAxiosRequestConfig = {
      headers: {
        Authorization: `${localRefreshToken}`,
      },
      skipAuthorizationInterceptor: true,
    };

    const resp = await axiosInstance.post('auth/refresh', {}, config);
    return resp.data;
  } catch (e) {
    console.log('Error', e);
  }
};

export const logout = async (navigate?: NavigateFunction): Promise<void> => {
  const jwtToken = localStorage.getItem('jwtToken');
  if (jwtToken) {
    try {
      await axiosInstance.delete(`auth/logout`);
      clearStorage();
      navigateToLoginPage();
    } catch (e) {
      handleError(e as Error);
    }
  }
};

export const registerUser = async (
  user: any
): Promise<AxiosResponse | undefined> => {
  try {
    const response = await axiosInstance.post('auth/register', user);
    handleSuccess(
      'If the email is valid, we will send you a verification code in your Mail inbox.'
    );
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const activateAccount = async ({
  email,
  code,
}: {
  email: string;
  code: string;
}): Promise<AxiosResponse | undefined> => {
  try {
    const response = await axiosInstance.post(
      'auth/register/validate_account',
      {
        email,
        code,
      }
    );
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const validateEmail = async (
  email: string
): Promise<boolean | undefined> => {
  try {
    const response = await axiosInstance.post('auth/register/check_username', {
      email,
    });
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error: any) {
    if (axios?.isAxiosError(error)) {
      const err = error as AxiosError;
      if (err?.status === 404) {
        return false;
      }
    }
  }
};

export const requestResetPasswordEmail = async (
  email: string
): Promise<AxiosResponse | undefined> => {
  try {
    const response = await axiosInstance.post(
      `auth/settings/request_password_reset`,
      {
        email,
      }
    );
    handleSuccess(
      'If the email is valid, we will send you an email to reset your password.'
    );
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const resetPassword = async (
  { newPassword }: ResetPasswordSchemaType,
  token: string | null
): Promise<AxiosResponse | undefined> => {
  try {
    if (token) {
      const response = await axiosInstance.patch(`auth/reset_password`, {
        new_password: newPassword,
        token,
      });
      return response;
    }
  } catch (error: any) {
    handleError(error);
  }
};
