import { BackButton } from '@components/backButton';
import { useFetchProject } from 'api/projects/projectsServices';
import { type FC, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { DetailsHeader } from './components/DetailsHeader';
import { downloadPDF } from 'utils/pdf/pdfUtils';
import { DescriptionContainer } from './components/DescriptionContainer';
import { SavingsContainer } from './components/SavingsContainer';
import { TargetContainer } from './components/TargetContainer';
import { SavingsCharts } from './components/SavingsCharts';
import { ModelAndSavingsDetails } from './components/ModelAndSavingsDetails';
import { useNotFoundPage } from 'hooks/useNotFoundPage';

const PROJECTS_LIST_PATH = '/app/management/projects';

const ProjectDetailsPage: FC = () => {
  const pdfRef = useRef<HTMLDivElement | null>(null);
  const { validateAndRedirectToNotFound } = useNotFoundPage();
  const { id } = useParams();

  const { data, isLoading, isError, error } = useFetchProject(id);

  useEffect(() => {
    validateAndRedirectToNotFound(isError, error);
  }, [isError, error]);

  const isCurrentTargetReached = (): boolean => {
    const currentSavings = data?.project?.baseline?.savings_rel ?? 0;
    const savingsTarget = data?.project?.savings_target ?? 0;

    return currentSavings >= savingsTarget;
  };

  return (
    <>
      <div className="flex flex-col gap-6" ref={pdfRef}>
        <BackButton
          navigateTo={PROJECTS_LIST_PATH}
          goBackIfPreviousPage={['/app/operations/anomalies']}
        />
        <DetailsHeader
          projectName={data?.project?.name}
          mainBuildingName={data?.project.name}
          meter={data?.project?.meter}
          referencePeriodStart={data?.project?.reference_period_start}
          referencePeriodEnd={data?.project?.reference_period_end}
          implementationPeriodStart={data?.project?.implementation_period_start}
          implementationPeriodEnd={data?.project?.implementation_period_end}
          isLoading={isLoading}
          downloadHandler={async () => {
            await downloadPDF(pdfRef.current);
          }}
        />
        <DescriptionContainer
          projectDescription={data?.project?.description}
          isLoading={isLoading}
        />
        <div className="flex flex-row gap-6">
          <div className="flex-1 basis-2/3">
            <SavingsContainer
              isLoading={isLoading}
              savingsInEuro={data?.project?.baseline?.savings_euro ?? 0}
              totalSavings={data?.project?.baseline?.savings_total ?? 0}
              utilityType={data?.project?.meter?.unit_type}
              enableNumericColorVisualization={isCurrentTargetReached()}
            />
          </div>
          <div className="flex-1 basis-1/3">
            <TargetContainer
              isLoading={isLoading}
              savingsTarget={data?.project?.savings_target ?? 0}
              currentSavings={data?.project?.baseline?.savings_rel ?? 0}
            />
          </div>
        </div>
        <SavingsCharts
          isLoading={isLoading}
          id={id}
          unit={data?.project?.meter?.unit_type}
          savingsTarget={data?.project?.savings_target}
        />
        <ModelAndSavingsDetails
          data={data?.project?.baseline}
          isLoading={isLoading}
          referencePeriodStart={data?.project?.reference_period_start}
          referencePeriodEnd={data?.project?.reference_period_end}
        />
      </div>
    </>
  );
};
export default ProjectDetailsPage;
